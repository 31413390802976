import type { FormEventHandler } from 'react';
import React, { useReducer } from 'react';

import { EventType } from '@autofidev/notifications';
import type { Dealer } from '@autofidev/models/generated';

import { useGoogleTagScript } from '@client/hooks/useGoogleTagScript';
import api from '@client/lib/api';
import { ActionType, initialState, reducer } from '@client/routes/Identity/utils';
import type { Props as IdentityFormProps } from '@client/routes/Identity/IdentityForm';
import { IdentityForm } from '@client/routes/Identity/IdentityForm';
import { endpointBaseUrl } from '@public/v3/request';
import { override } from '@client/lib/helpers/urlOverrides';
import { trackFourEyesLoanAppSubmission } from '@client/lib/helpers/analytics';

import './index.scss';

const createStandAloneCreditApplication = `
mutation CreateStandAloneCreditApplication($input: CreateStandAloneCreditApplicationInput!) {
	createStandAloneCreditApplication(input: $input) {
		... on LoanApplicationDetailed {
			_id
			dealer {
				settings {
					disableBeganSCANotificationsForSubmittedApp
					disableBeganSCANotificationsThreshold
				}
			}
			urlToken
		}
		... on AutoFiError {
			code
			message
			errors {
				message
			}
		}
	}
}
`;

export interface Props extends Omit<IdentityFormProps, 'handleSubmit' | 'handleTaxExemptToggle'> {
	dealer: Dealer;
	isInStore: boolean;
}

export const StandAloneCreditApplication = (props: Omit<Props, 'launchDarklyFeatureFlags'>) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { dealer } = props;

	useGoogleTagScript({ dealer });

	const handleTaxExemptToggle: FormEventHandler<HTMLInputElement> = (event) => {
		event.preventDefault();

		const newIsExempt = !props.isTaxExempt;

		dispatch({ type: ActionType.SetTaxExempt, isTaxExempt: newIsExempt });
	};

	const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
		event.preventDefault();
		dispatch({ type: ActionType.SetIsSubmitting, isSubmitting: true });
		// TODO: loading indicator time
		try {
			const {
				renderFieldEmail,
				renderFieldNameFirst,
				renderFieldNameLast,
				renderFieldNameMiddle,
				renderFieldPhone,
				renderFieldTaxExempt,
				renderFieldTitle,
			} = props.fields;
			const { email, firstName, lastName, middleName, phone, isTaxExempt, title, dealerAssociateId } = state;

			const { data, errors } = await api.post(`${endpointBaseUrl}/graphql`, {
				query: createStandAloneCreditApplication,
				variables: {
					input: {
						dealerAssociateId: dealerAssociateId === '' ? null : dealerAssociateId,
						dealerCode: dealer.code,
						backendPath: 'PROD',
						email: renderFieldEmail ? email : undefined,
						firstName: renderFieldNameFirst ? firstName.trim().replace(/( + )/g, ' ') : undefined,
						lastName: renderFieldNameLast ? lastName.trim().replace(/( + )/g, ' ') : undefined,
						middleName: renderFieldNameMiddle ? middleName : undefined,
						phone: renderFieldPhone ? phone.replace(/[^\d]/g, '') : undefined,
						isTaxExempt: renderFieldTaxExempt ? isTaxExempt : undefined,
						salutation: renderFieldTitle ? title : undefined,
					},
				},
			});

			const urlToken = data?.createStandAloneCreditApplication?.urlToken;
			const loanAppId = data?.createStandAloneCreditApplication?._id;
			const isInStore = override.isEnabled('af-instore');
			const dealerDisableBeganSCANotificationsForSubmittedApp =
				data?.createStandAloneCreditApplication?.dealer?.settings?.disableBeganSCANotificationsForSubmittedApp || false;
			const dealerDisableBeganSCANotificationsThreshold =
				data?.createStandAloneCreditApplication?.dealer?.settings?.disableBeganSCANotificationsThreshold || 0;

			if (urlToken) {
				const event = {
					loanApplicationId: loanAppId,
					notificationType: EventType.BeganStandAloneCreditApplication,
					notificationPayload: {
						loanApplicationId: loanAppId,
						customerWasAnonymousUntilPII: true,
						actionPerformedInStore: isInStore,
					},
					delayNotificationTrigger: dealerDisableBeganSCANotificationsForSubmittedApp
						? dealerDisableBeganSCANotificationsThreshold * 60000
						: null,
				};

				await api.post('/notification', event);

				trackFourEyesLoanAppSubmission({ email, firstName, lastName, phone, urlToken });

				const params = new URLSearchParams({
					piiStep: '1',
					embedded: 'true',
					...(isInStore && { 'af-instore': 'true' }),
				});
				window.location.assign(`${window.baseUrl}/t2/${urlToken}?${params}`);
			}
			if (errors) {
				// TODO: do something with the errors
				dispatch({ type: ActionType.SetIsSubmitting, isSubmitting: false });
			}
		} catch (e) {
			// TODO: do something with this
			dispatch({ type: ActionType.SetIsSubmitting, isSubmitting: false });
		}
	};
	return (
		<IdentityForm
			{...props}
			{...state}
			handleSubmit={handleSubmit}
			handleTaxExemptToggle={handleTaxExemptToggle}
			hideStandAloneClose
			outerDispatch={dispatch}
		/>
	);
};
