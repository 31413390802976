import type { Dealer } from '@autofidev/models/generated';

import { isEmbedded } from '../isEmbedded';
import { postMessage } from '../postMessage';

/*
 * Validates if we should inject JDPower scripts into the dealer site
 * @param {Object} dealer - currentDealer
 */
export const shouldAddJDPowerScripts = (dealer: Dealer) => {
	const { brand, settings } = dealer;

	const isJdPowerMember = settings.analytics?.jdPower?.enabled;
	const trackingEnabled = settings.analytics?.jdPower?.trackingEnabled;
	const country = dealer.address?.contracts?.country;

	return Boolean(['honda', 'acura'].includes(brand) && isJdPowerMember && trackingEnabled && country === 'US');
};

/*
 * Validates if the dealer should track events for JD Power
 */
const shouldTrackJDPowerEvents = (dealer: Dealer) => {
	const oemSalesCode = dealer.settings?.oem?.dealerNumber;

	return Boolean(shouldAddJDPowerScripts(dealer) && oemSalesCode && isEmbedded());
};

type DRSEventValueType =
	| 'DRS-Credit-Application'
	| 'DRS-Payment-Calculator'
	| 'DRS-Trade-in'
	| 'DRS-Test-Drive'
	| 'DRS-Credit-Application-End'
	| 'DRS-Payment-Calculator-End'
	| 'DRS-Trade-in-End'
	| 'DRS-Test-Drive-End';

export const trackJdPowerEvent = (eventValue: DRSEventValueType, dealer: Dealer) => {
	const embedded = isEmbedded();
	/**
	 * @type {('drs'|'mps')}
	 */
	const eventType = eventValue.includes('DRS-') ? 'drs' : 'mps';
	const oemSalesCode = dealer.settings?.oem?.dealerNumber;
	const shouldTrack = shouldTrackJDPowerEvents(dealer);

	if (!shouldTrack) {
		// Track event only for honda dealers with all requirements set
		// JD Power members with tracking enabled
		return;
	}

	if (!embedded) {
		// Track event using postMessage only for non-embedded pages
		return postMessage({ type: 'autofi-jd-power-event', eventType: eventValue });
	}

	if (!Array.isArray(window.dataLayer)) {
		return;
	}

	window.dataLayer.push({
		event: eventType,
		[eventType === 'drs' ? 'DRSType' : 'MPSType']: eventValue,
		Dealerid: oemSalesCode,
		ProviderID: 'AUT',
		'content-group': 'DRS',
		vehicle: {},
	});
};
