const { EventType } = require('@autofidev/notifications/built/types');

const NOTIFICATION_TYPES = {
	APPOINTMENT_SCHEDULED: 'APPOINTMENT SCHEDULED',
	REQUEST_ESIGN: 'REQUEST ESIGN CODE',
	OPENED_SENT_OFFER: 'OPENED SENT OFFER',
};

const APPSTATES = {
	NO_CUSTOMER: 'No Customer',
	LEAD: 'Lead',
	BEGAN_APPLICATION: 'Began Application',
	SUBMITTED_APPLICATION: 'Submitted Application',
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	PENDING: 'Pending',
	READY_TO_SIGN: 'Ready to Sign',
	FUNDED: 'Funded',
	ERROR: 'Error',
};

const LOANAPP_SOURCE = {
	AALRT: 'AALRT',
	AATST: 'AATST',
	VRMO: 'VRMO',
	VRMOTEST: 'VTST',
	RDST: 'RDST',
	// TODO: Add Roadster Test
};

const LOANAPP_PRICING_STATUS = {
	APPROVED: 'APPROVED',
	COMPLETED: 'COMPLETED',
	DECLINED: 'DECLINED',
	INPROGRESS: 'INPROGRESS',
	NOTSUBMITTED: 'NOTSUBMITTED',
	RECOVERABLEERROR: 'RECOVERABLEERROR',
	UNRECOVERABLEERROR: 'UNRECOVERABLEERROR',
};

const PATHWAYS = {
	CREDIT_APP: 'creditapp',
	DEPOSIT: 'deposit',
	EXPLORE_PAYMENTS: 'explorepayments',
	REQUEST_MORE_INFO: 'requestinfo',
	PRIVATE_OFFERS: 'privateoffers',
	TEST_DRIVE: 'testdrive',
	TRADEIN: 'tradein',
	UNLOCK_PRICING: 'unlockpricing',
	CREATED_FROM_LINK: 'link',
	CREATED_FROM_TEXT: 'text',
};

const DEFAULT_CREDIT_BANDS = [
	{ label: 'FAIR', labelText: '500-600', minFico: 500, maxFico: 600, checked: false },
	{ label: 'AVERAGE', labelText: '601-660', minFico: 601, maxFico: 660, checked: false },
	{ label: 'GOOD', labelText: '661-780', minFico: 661, maxFico: 780, checked: false },
	{ label: 'EXCELLENT', labelText: '781-850', minFico: 781, maxFico: 850, checked: true },
];

const JD_POWER_DRS_PROGRAM_CODES_DESKTOP = {
	honda: {
		[EventType.EnteredDealMaker]: '90800',
		[EventType.EnteredDealMakerCash]: '90800',
		[EventType.Deposit]: '90810',
		[EventType.NoPaymentLead]: '90818',
		[EventType.ScheduledTestDrive]: '90808',
		[EventType.MoreInfoRequested]: '90810',
		[EventType.OpenedSentOffer]: '90810',
		[EventType.CreditEstimator]: '90804',
		[EventType.BeganStandAloneCreditApplication]: '90806',
		[EventType.SubmittedStandAloneCreditApplication]: '90806',
		[EventType.TradeIn]: '90802',
		[EventType.TradeInOfferShownToCustomer]: '90802',
		[EventType.InstantCashOfferCompleted]: '90802',
		[EventType.InstantCashOfferApplied]: '90802',
		[EventType.InstantCashOfferRemoved]: '90802',
		[EventType.AppointmentScheduled]: '90810',
		[EventType.BeganApplication]: '90806',
		[EventType.Approved]: '90820',
		[EventType.Declined]: '90821',
		[EventType.Pending]: '90822',
		[EventType.NoLender]: '90823',
		[EventType.Error]: '90824',
		[EventType.SchedulePickup]: '90825',
		[EventType.ScheduleDelivery]: '90826',
		[EventType.ESignRequested]: '90827',
	},
	acura: {
		[EventType.EnteredDealMaker]: '95036',
		[EventType.EnteredDealMakerCash]: '95036',
		[EventType.Deposit]: '95046',
		[EventType.NoPaymentLead]: '95052',
		[EventType.ScheduledTestDrive]: '95044',
		[EventType.MoreInfoRequested]: '95046',
		[EventType.OpenedSentOffer]: '95046',
		[EventType.CreditEstimator]: '95040',
		[EventType.BeganStandAloneCreditApplication]: '95042',
		[EventType.SubmittedStandAloneCreditApplication]: '95042',
		[EventType.TradeIn]: '95038',
		[EventType.TradeInOfferShownToCustomer]: '95038',
		[EventType.InstantCashOfferCompleted]: '95038',
		[EventType.InstantCashOfferApplied]: '95038',
		[EventType.InstantCashOfferRemoved]: '95038',
		[EventType.AppointmentScheduled]: '95046',
		[EventType.BeganApplication]: '95042',
		[EventType.Approved]: '95056',
		[EventType.Declined]: '95057',
		[EventType.Pending]: '95058',
		[EventType.NoLender]: '95059',
		[EventType.Error]: '95060',
		[EventType.SchedulePickup]: '95061',
		[EventType.ScheduleDelivery]: '95061',
		[EventType.ESignRequested]: 'TODO',
	},
};

const JD_POWER_DRS_PROGRAM_CODES_MOBILE = {
	honda: {
		[EventType.EnteredDealMaker]: '90801',
		[EventType.EnteredDealMakerCash]: '90801',
		[EventType.Deposit]: '90811',
		[EventType.NoPaymentLead]: '90819',
		[EventType.ScheduledTestDrive]: '90809',
		[EventType.MoreInfoRequested]: '90811',
		[EventType.OpenedSentOffer]: '90811',
		[EventType.CreditEstimator]: '90805',
		[EventType.BeganStandAloneCreditApplication]: '90807',
		[EventType.SubmittedStandAloneCreditApplication]: '90807',
		[EventType.TradeIn]: '90803',
		[EventType.TradeInOfferShownToCustomer]: '90803',
		[EventType.InstantCashOfferCompleted]: '90803',
		[EventType.InstantCashOfferApplied]: '90803',
		[EventType.InstantCashOfferRemoved]: '90803',
		[EventType.AppointmentScheduled]: '90811',
		[EventType.BeganApplication]: '90807',
		[EventType.Approved]: '90820',
		[EventType.Declined]: '90821',
		[EventType.Pending]: '90822',
		[EventType.NoLender]: '90823',
		[EventType.Error]: '90824',
		[EventType.SchedulePickup]: '90825',
		[EventType.ScheduleDelivery]: '90826',
		[EventType.ESignRequested]: '90827',
	},
	acura: {
		[EventType.EnteredDealMaker]: '95037',
		[EventType.EnteredDealMakerCash]: '95037',
		[EventType.Deposit]: '95047',
		[EventType.NoPaymentLead]: '95053',
		[EventType.ScheduledTestDrive]: '95045',
		[EventType.MoreInfoRequested]: '95047',
		[EventType.OpenedSentOffer]: '95047',
		[EventType.CreditEstimator]: '95041',
		[EventType.BeganStandAloneCreditApplication]: '95043',
		[EventType.SubmittedStandAloneCreditApplication]: '95043',
		[EventType.TradeIn]: '95039',
		[EventType.TradeInOfferShownToCustomer]: '95039',
		[EventType.InstantCashOfferCompleted]: '95039',
		[EventType.InstantCashOfferApplied]: '95039',
		[EventType.InstantCashOfferRemoved]: '95039',
		[EventType.AppointmentScheduled]: '95047',
		[EventType.BeganApplication]: '95043',
		[EventType.Approved]: '95056',
		[EventType.Declined]: '95057',
		[EventType.Pending]: '95058',
		[EventType.NoLender]: '95059',
		[EventType.Error]: '95060',
		[EventType.SchedulePickup]: '95061',
		[EventType.ScheduleDelivery]: '95061',
		[EventType.ESignRequested]: 'TODO',
	},
};

const CRM_TYPES_SERVICE_VALUE = {
	[EventType.EnteredDealMakerCash]: 'AutoFi Cash',
	[EventType.EnteredDealMaker]: 'AutoFi Lead',
	[EventType.InstantCashOfferApplied]: 'AutoFi ICO',
	[EventType.InstantCashOfferCompleted]: 'AutoFi ICO',
	[EventType.InstantCashOfferRemoved]: 'AutoFi ICO',
	[EventType.InStore]: 'AutoFi In store',
	[EventType.ScheduledTestDrive]: 'AutoFi Test Drive',
	[EventType.MoreInfoRequested]: 'AutoFi Request More Info',
	[EventType.OpenedSentOffer]: 'AutoFi Remote Deal',
	[EventType.CreditEstimator]: 'AutoFi Credit Estimator',
	[EventType.PrivateOfferViewedVin]: 'AutoFi Private Offer',
	[EventType.NoPrivateOfferFoundVin]: 'AutoFi Private Offer',
	[EventType.TradeIn]: 'AutoFi Trade in',
	[EventType.TradeInOfferShownToCustomer]: 'AutoFi Trade In Offer Shown To Customer',
	[EventType.AppointmentScheduled]: 'AutoFi Appointment',
	[EventType.BeganApplication]: 'AutoFi Began Application',
	[EventType.BeganStandAloneCreditApplication]: 'AutoFi Standalone App',
	[EventType.SubmittedStandAloneCreditApplication]: 'AutoFi Standalone App',
	[EventType.Approved]: 'AutoFi Approved',
	[EventType.Declined]: 'AutoFi Declined',
	[EventType.Pending]: 'AutoFi Pending',
	[EventType.NoLender]: 'AutoFi No Lender',
	[EventType.Error]: 'AutoFi Error',
	[EventType.SchedulePickup]: 'AutoFi Schedule Pickup',
	[EventType.ESignRequested]: 'AutoFi E-sign Requested',
	[EventType.NoPaymentLead]: 'AutoFi No Payment Lead',
	[EventType.Deposit]: 'Autofi Deposit',
	[EventType.ScheduleDelivery]: 'AutoFi Schedule Delivery',
	[EventType.ClosedDealMaker]: 'AutoFi Close Lead',
	[EventType.UserUploadedStipulationDocument]: 'AutoFi Document',
};

const UNITE_CRM_TYPES_SERVICE_VALUE = {
	[EventType.EnteredDealMakerCash]: 'AutoFi - Build Your Deal',
	[EventType.EnteredDealMaker]: 'AutoFi - Build Your Deal',
	[EventType.InstantCashOfferApplied]: 'AutoFi - Trade In',
	[EventType.InstantCashOfferCompleted]: 'AutoFi - Trade In',
	[EventType.InstantCashOfferRemoved]: 'AutoFi - Trade In',
	[EventType.InStore]: 'AutoFi - Build Your Deal',
	[EventType.ScheduledTestDrive]: 'AutoFi - Test Drive',
	[EventType.MoreInfoRequested]: 'AutoFi - Contact',
	[EventType.OpenedSentOffer]: 'AutoFi - Build Your Deal',
	[EventType.CreditEstimator]: 'AutoFi - Credit App',
	[EventType.PrivateOfferViewedVin]: 'AutoFi - Private Offer',
	[EventType.NoPrivateOfferFoundVin]: 'AutoFi - Private Offer',
	[EventType.TradeIn]: 'AutoFi - Trade In',
	[EventType.TradeInOfferShownToCustomer]: 'AutoFi - Trade In Offer Shown To Customer',
	[EventType.AppointmentScheduled]: 'AutoFi - Book Appointment',
	[EventType.BeganApplication]: 'AutoFi - Credit App',
	[EventType.BeganStandAloneCreditApplication]: 'AutoFi - Credit App',
	[EventType.SubmittedStandAloneCreditApplication]: 'AutoFi - Credit App',
	[EventType.Approved]: 'AutoFi - Credit App',
	[EventType.Declined]: 'AutoFi - Credit App',
	[EventType.Pending]: 'AutoFi - Credit App',
	[EventType.NoLender]: 'AutoFi - Credit App',
	[EventType.Error]: 'AutoFi - Credit App',
	[EventType.SchedulePickup]: 'AutoFi - Book Appointment',
	[EventType.ESignRequested]: 'AutoFi - E-sign Requested',
	[EventType.NoPaymentLead]: 'AutoFi - Build Your Deal',
	[EventType.Deposit]: 'AutoFi - Deposit',
	[EventType.ScheduleDelivery]: 'AutoFi - Book Appointment',
};

const MiDSPartnerCodes = {
	qa: {
		appointment: 'd9cd8188-739a-4b90-8a9e-752a298320f9',
		creditApp: '03b69efd-7891-4ef0-985b-11a386621c75',
		requestMoreInfo: 'c6a70cae-8480-4690-879b-f856fc5ad445',
		startOffer: '5f28ba63-08b7-4865-86e6-1e4898023f5b',
		testDrive: '2d485cac-912d-4ae0-967a-b25fd1027014',
		tradeIn: '016703f3-6119-41d5-88ac-6eb122c59a8c',
	},
	prod: {
		appointment: '004b8dfa-960e-47bf-a55a-118c2eaf74e4',
		creditApp: '28c693db-8b6e-419e-859d-4cbc5c179525',
		requestMoreInfo: 'f30594a7-f775-4a7e-b7e7-145c23b9e367',
		startOffer: 'ff7ea871-254d-4482-9294-2255802b9b7d',
		testDrive: '9585a7ab-638c-400b-ae4e-4e270e7b8fa6',
		tradeIn: '7938add3-3cea-4f5f-acb3-eb2df9a4ae39',
	},
};

const VWPartnerCodes = {
	qa: {
		appointment: '0f77ca2e-679f-4830-923b-d2e0a3757f4d',
		creditApp: '0074ad90-2b06-47bf-8d04-b9149aad7f50',
		deposit: 'b2cef7ac-8575-4053-8730-ac7f90fafa7a',
		esign: '56f70ed4-61aa-400c-9f71-728047d04387',
		privateOffer: '1651584d-2252-42fe-9f0d-87392afe8ffd',
		requestMoreInfo: 'fca7a922-f612-4b8f-9a6f-930e10e658d7',
		startOffer: '2e6faf47-19aa-4054-ab9f-10e42e0250bd',
		testDrive: '8a0c3e79-e38c-4ca2-ab26-95cf761cf0ea',
		tradeIn: '2a48720d-936b-4b9c-b13a-1834084901bf',
	},
	prod: {
		appointment: '3d26d8ee-9948-47c9-ab25-39e5cbc95826',
		creditApp: '66dbeb4e-8887-4e91-b9dd-3c25b67a13fa',
		deposit: 'ec7d053f-87a1-4f9f-b50c-133d53c5c968',
		esign: 'dce93784-a15f-4c07-b9ee-e7f7a92c81ef',
		privateOffer: '19a047da-193e-4a13-9125-0f60e064a8a6',
		requestMoreInfo: '9afa1487-c8d0-4e53-96ec-9c7ff702f335',
		startOffer: 'caadc437-3fce-4042-8b85-eb859a99d412',
		testDrive: '5f9b6d6f-9039-4c7b-bbf2-0b68dba6b889',
		tradeIn: 'f875b295-41da-4743-86bf-9efeb46c6cd1',
	},
};

const REMOTE_DEAL_SOURCES = ['link', 'text', 'QRCode', 'ThirdPartyLeads'];

// The following list was copied from
// https://github.com/AutoFi/autofi/blob/master/packages/utils/src/enums/dealer.js
// with the names changed to lowercase. TODO: find a way to eliminate duplication
// and keep the brands in one place and make them availbale in lowercase, with
// autocomplete continuing to work like it currently does.
const BRANDS = {
	ACURA: 'acura',
	ALFA_ROMEO: 'alfa romeo',
	ASTON_MARTIN: 'aston martin',
	AUDI: 'audi',
	BENTLEY: 'bentley',
	BMW: 'bmw',
	BUGATTI: 'bugatti',
	BUICK: 'buick',
	CADILLAC: 'cadillac',
	CHEVROLET: 'chevrolet',
	CHRYSLER: 'chrysler',
	DODGE: 'dodge',
	FERRARI: 'ferrari',
	FIAT: 'fiat',
	FORD: 'ford',
	FREIGHTLINER: 'freightliner',
	GENESIS: 'genesis',
	GMC: 'gmc',
	HONDA: 'honda',
	HUMMER: 'hummer',
	HYUNDAI: 'hyundai',
	INFINITI: 'infiniti',
	JAGUAR: 'jaguar',
	JEEP: 'jeep',
	KIA: 'kia',
	LAMBORGHINI: 'lamborghini',
	LAND_ROVER: 'land rover',
	LEXUS: 'lexus',
	LINCOLN: 'lincoln',
	LOTUS: 'lotus',
	MASERATI: 'maserati',
	MAZDA: 'mazda',
	MCLAREN: 'mclaren',
	MERCEDES_BENZ: 'mercedes-benz',
	MINI: 'mini',
	MITSUBISHI: 'mitsubishi',
	NISSAN: 'nissan',
	PORSCHE: 'porsche',
	RAM: 'ram',
	ROLLS_ROYCE: 'rolls-royce',
	SMART: 'smart',
	SOUTHEAST_TOYOTA: 'southeast toyota',
	SUBARU: 'subaru',
	TESLA: 'tesla',
	TOYOTA: 'toyota',
	VOLKSWAGEN: 'volkswagen',
	VOLVO: 'volvo',
	GROUP_SITE: 'group site',
	NON_FRANCHISED: 'non-franchised',
};

const COMMENT_SOURCE_MAP = {
	[EventType.AppointmentScheduled]: 'appointment',
	[EventType.MoreInfoRequested]: 'requestinfo',
	[EventType.ScheduledTestDrive]: 'testdrive',
	// add more as needed
};

const DSGSS_RESIDENCE_TYPE = {
	BUYING: 'own',
	OWN: 'own',
	RENT: 'rent',
	RELATIVES: 'lives with parents',
	NA: '',
};

const DSGSS_EMPLOYMENT_STATUS = {
	EMPLOYED: 'Employed',
	UNEMPLOYED: 'Unemployed',
	STUDENT: 'Student',
	OTHER: 'Other',
	RETIRED: 'Retired',
	SELF_EMPLOYED: 'SelfEmployed',
	MILITARY: 'ActiveMilitary',
	NA: '',
};

const SONIC_DEALER_CODES = [
	'UF5F',
	'O25M',
	'QHEH',
	'KC68',
	'M0Q0',
	'XD5A',
	'PENN',
	'A6UP',
	'0OX4',
	'6QC3',
	'85PU',
	'DMC1',
	'PJJN',
	'G9GC',
	'0IK5',
	'YDW4',
	'WQ8T',
	'1PHM',
	'FQSX',
	'2TZG',
	'Z1CB',
	'RS3I',
	'BD8L',
	'8AUP',
	'QF96',
	'3CBQ',
	'TD85',
	'Z4AL',
	'J4X1',
	'NX1D',
	'94BA',
	'KWC6',
	'8LKF',
	'HXPW',
	'U1SQ',
	'EXUW',
	'8EZP',
	'VGP1',
	'QFMQ',
	'SUT0',
	'0ZOO',
];

module.exports = {
	APPSTATES,
	BRANDS,
	CRM_TYPES_SERVICE_VALUE,
	COMMENT_SOURCE_MAP,
	UNITE_CRM_TYPES_SERVICE_VALUE,
	DEFAULT_CREDIT_BANDS,
	LOANAPP_SOURCE,
	LOANAPP_PRICING_STATUS,
	NOTIFICATION_TYPES,
	PATHWAYS,
	REMOTE_DEAL_SOURCES,
	MiDSPartnerCodes,
	VWPartnerCodes,
	JD_POWER_DRS_PROGRAM_CODES_DESKTOP,
	JD_POWER_DRS_PROGRAM_CODES_MOBILE,
	SONIC_DEALER_CODES,
	DSGSS_RESIDENCE_TYPE,
	DSGSS_EMPLOYMENT_STATUS,
};
